* {
    margin: 0;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 221);
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/*
background: #0e0e0e
headers: white
text: rgba(255, 255, 255, 221)
*/

html, body {
    height: 100%;
    background: #0e0e0e;
}

h1, h2 {
    color: rgba(255, 255, 255, 221);
    margin-bottom: 16px;
}

.container {
    margin: 24px 16px 0;
    padding-bottom: 24px;
}

.mdc-button {
    margin-bottom: 8px;
}
